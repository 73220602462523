import { getParentSpan, setSemanticAttributes } from '@local/metrics';

export function addFetchAttributesToSpan(span: any, _req: any, _res: any): void {
    const parentSpan = getParentSpan();
    const parentContext = parentSpan?.spanContext();
    const context = span.spanContext();

    setSemanticAttributes(span);
    if (parentContext) {
        const attributes = {
            'http.scheme': 'render',
            'parent.id': parentContext.spanId,
            'trace.id': parentContext.traceId,
        };
        span.addLink({ context: parentContext, attributes });
        parentSpan?.addLink({ context });
    }
}

export function addXHRAttributesToSpan(span: any, _xhr: any): void {
    setSemanticAttributes(span);
}
